import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './languages/en.json';
import de from './languages/de.json';
import sr from './languages/sr.json';

const resources = {
    de: {
        translation: de,
    },
    en: {
        translation: en,
    },
    sr: {
        translation: sr,
    },

};
export const availableLanguages = Object.keys(resources);

i18n.use(initReactI18next)
    .init({
        compatibilityJSON: 'v3',//issue on older versions of phone
        resources,
        defaultNS: 'translation',
        fallbackLng: localStorage.getItem('language') || 'de',
    });
