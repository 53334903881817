import React from 'react';
import {Form} from 'react-bootstrap';
import {FieldErrors, UseFormRegister} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {IInvoiceData} from '../app/form/InvoiceForm';


interface IProps {
    register: UseFormRegister<IInvoiceData>;
    errors: FieldErrors<IInvoiceData>
}

const InvoiceHeader: React.FC<IProps> = ({register, errors}) => {
    const {t} = useTranslation()
    return (
        <div className="d-flex align-items-start justify-content-between pb-3">
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                    <span className="fw-bold w-50">{t('general.dateOfIssue')}: </span>
                    <div className="d-flex flex-column ms-2">
                        <Form.Control type="date" {...register('payUntil')}
                                      isInvalid={!!errors?.payUntil}
                                      className="w-100"/>
                        {errors?.payUntil && <div className="invalid-feedback">
                            {errors?.payUntil?.message}
                        </div>}
                    </div>
                </div>
                <div className="d-flex align-items-center ms-2">
                    <span className="fw-bold w-50">{t('general.dateOfWork')}: </span>
                    <div className="d-flex flex-column ms-2">
                        <Form.Control type="text" {...register('dateOfWork')}
                                      isInvalid={!!errors?.dateOfWork}
                                      className="w-100"/>
                        {errors?.dateOfWork && <div className="invalid-feedback">
                            {errors?.dateOfWork?.message}
                        </div>}
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-end">
                <span className="fw-bold me-2">{t('general.nbOfInvoice')}: </span>
                <div className="d-flex flex-column">
                    <Form.Control type="text" placeholder={`${t('general.nbOfInvoice')}...`}
                                  {...register('nbOfInvoice')}
                                  isInvalid={!!errors?.nbOfInvoice}
                                  className="w-100"/>
                    {errors?.nbOfInvoice && <div className="invalid-feedback">
                        {errors?.nbOfInvoice?.message}
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default InvoiceHeader;
