import React from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import {FieldErrors, UseFormRegister} from 'react-hook-form';
import {IInvoiceData} from '../app/form/InvoiceForm';
import BillFrom from './BillFrom';
import BillTo from './BillTo';
import InvoiceHeader from './InvoiceHeader';


interface IProps {
    register: UseFormRegister<IInvoiceData>;
    errors: FieldErrors<IInvoiceData>
}

const InvoiceCard: React.FC<IProps> = ({register, errors}) => {
    return (
        <Card>
            <Card.Body className="p-md-5">
                <Row className="gy-3">
                    <Col md={12} className="border-bottom">
                        <InvoiceHeader register={register} errors={errors}/>
                    </Col>
                    <Col md={6}>
                        <BillFrom register={register} errors={errors}/>
                    </Col>
                    <Col md={6}>
                        <BillTo register={register} errors={errors}/>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default InvoiceCard;
