import {collection, getDocs} from 'firebase/firestore';
import i18n from 'i18next';
import React, {useEffect, useState} from 'react';
import {Button, Card, Container} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {auth, db} from '../../firebase';
import {availableLanguages} from '../../localization/i18n';
import authSlice from '../../store/slices/auth.slice';
import {IInvoiceData} from '../form/InvoiceForm';
import TableList from './components/TableList';
import {signOut} from 'firebase/auth';


const PreviewInvoices = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const [invoices, setInvoices] = useState<IInvoiceData[]>([]);

    const handleChangeLanguage = async (newLng: string) => {
        localStorage.setItem('language', newLng)
        await i18n.changeLanguage(newLng);
    }

    useEffect(() => {
        const fetchInvoices = async () => {

            await getDocs(collection(db, 'invoices'))
                .then((querySnapshot) => {
                    const newData = querySnapshot.docs
                        // @ts-ignore
                        .map((doc) => {
                            return ({...doc.data()?.invoices, id: doc.id})
                        });
                    setInvoices(newData as unknown as IInvoiceData[]);
                })
        }
        fetchInvoices()
    }, []);
    return (
        <>
            <Container className="mt-3 w-100">
                <div className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-0 fw-bold">
                        {t('general.invoiceList')}
                    </h5>
                    <div className="w-75 d-flex align-items-center justify-content-end">
                        <div className="w-md-50 me-2">
                            <select
                                onChange={(e) => handleChangeLanguage(e.currentTarget.value)}
                                className="form-select w-100" aria-label="Default select example">
                                <option selected>{t(`language.${i18n.language}`)}</option>
                                {availableLanguages?.filter(language => language !== i18n.language)?.map(language => {
                                    return (
                                        <option
                                            key={language}
                                            value={language}>
                                            {t(`language.${language}`)}
                                        </option>

                                    )
                                })}
                            </select>
                        </div>
                        <Button onClick={() => navigation('/create-invoice')}>
                            + {t('general.createNewInvoice')}
                        </Button>
                        <Button variant="info" className="ms-2 text-white"
                                onClick={() => navigation('/word-file')}>
                            + {t('general.createNewWord')}
                        </Button>
                    </div>
                </div>

                <Card className="mt-2">
                    <Card.Body>
                        <TableList invoices={invoices} setInvoices={setInvoices}/>
                    </Card.Body>
                </Card>
                <div className="mt-2 d-flex justify-content-end">
                    <Button variant="info" className="text-white" onClick={() => {
                        signOut(auth).then(response => {
                            console.log(response);
                            dispatch(authSlice.actions.setLogoutState())
                            navigation('/');
                        });

                    }}>
                        Odjavi se sa platforme
                    </Button>
                </div>
            </Container>
        </>
    );
};

export default PreviewInvoices;
