import React from 'react';
import {Spinner} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

const LoadingScreen = () => {
    const {t} = useTranslation();
    return (
        <div className="d-flex align-items-center justify-content-center App flex-column">
            <img src="/logo.png" alt="Company logo" className="breathing-image"/>
            <h5 className="mt-5 d-flex align-items-center">{t('general.loading')}
                <Spinner animation="border" className="ms-2" role="status"/>
            </h5>
        </div>
    );
};

export default LoadingScreen;
