import {onAuthStateChanged} from 'firebase/auth';
import React, {useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import './localization/i18n';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Routes, useNavigate} from 'react-router-dom';
import InvoiceForm from './app/form/InvoiceForm';
import PreviewInvoices from './app/invoice-list';
import InvoiceUpdate from './app/invoice-update';
import LoginPage from './app/login';
import WordFile from './app/word-file';
import LoadingScreen from './components/LoadingScreen';
import Toaster from './components/Toaster';
import {auth} from './firebase';
import authSlice from './store/slices/auth.slice';
import {RootState} from './store/store';


function App() {
    const dispatch = useDispatch();
    const {isAuthenticated, isAppReady} = useSelector((state: RootState) => state.auth);
    const navigation = useNavigate();
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                dispatch(authSlice.actions.setLoginState(user))
            } else {
                dispatch(authSlice.actions.setLoginState({isAuthenticated: false, user: null}))
                dispatch(authSlice.actions.setLogoutState())
                navigation('/');
            }
            setTimeout(() => {
                dispatch(authSlice.actions.setIsAppReady(true))
            }, 500)
        });

        // Cleanup function to unsubscribe when component unmounts or is re-rendered
        return () => unsubscribe();
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (isAuthenticated) {
            navigation('/invoice-list')
        }
        // eslint-disable-next-line
    }, [isAuthenticated]);
    return (
        <>
            {isAppReady ?
                <>
                    <Routes>
                        {isAuthenticated ? <>
                                <Route path="/word-file" element={<WordFile/>}/>
                                <Route path="/invoice-list" element={<PreviewInvoices/>}/>
                                <Route path="/create-invoice" element={<InvoiceForm/>}/>
                                <Route path="/update-invoice/:id" element={<InvoiceUpdate/>}/>
                            </> :
                            <>
                                <Route path="/" element={<LoginPage/>}/>
                            </>}
                    </Routes>

                    <Toaster/>

                </>
                : <LoadingScreen/>}
        </>
    );
}

export default App;
