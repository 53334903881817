import {deleteDoc, doc} from 'firebase/firestore';
import React, {SetStateAction} from 'react';
import {Badge, Col, Row} from 'react-bootstrap';
import {Pencil, Trash} from 'react-bootstrap-icons';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {db} from '../../../firebase';
import {formatDate} from '../../../functions/formatDate';
import {getStatusColor} from '../../../functions/getStatusColor';
import {toastSlice} from '../../../store/slices/toasts.slice';
import {IInvoiceData} from '../../form/InvoiceForm';


interface IProps {
    invoices: IInvoiceData[];
    setInvoices: React.Dispatch<SetStateAction<IInvoiceData[]>>
}

const TableList: React.FC<IProps> = ({invoices, setInvoices}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleDeleteInvoice = async (invoiceId: string) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm(`${t('general.areYouSureDelete')} ${invoiceId}`)) {
            try {
                // Reference to the document you want to delete
                const invoiceRef = doc(db, 'invoices', invoiceId);

                // Delete the document
                await deleteDoc(invoiceRef);
                dispatch(toastSlice.actions.setToast({
                    type: 'success',
                    message: `Successfully deleted invoice ${invoiceId}`
                }));
                setInvoices(prev => prev.filter(invoice => invoice.id !== invoiceId))
            } catch (error) {
                console.error('Error deleting document: ', error);
                dispatch(toastSlice.actions.setToast({
                    type: 'danger',
                    message: error
                }))
            }
        }
    }

    return (
        <div className="mx-md-5 my-md-4">
            <Row className="bg-secondary-subtle py-3 border-radius">
                <Col md={1} className="d-flex align-items-center justify-content-center">
                    <h6 className="mb-0 fw-bold text-center">ID</h6>
                </Col>
                <Col md={1} className="d-flex align-items-center justify-content-center">
                    <h6 className="mb-0 fw-bold text-center">{t('general.nbOfInvoice')}</h6>
                </Col>
                <Col md={4} className="d-flex align-items-center justify-content-center">
                    <h6 className="mb-0 fw-bold text-center">{t('general.billTo')}</h6>

                </Col>
                <Col md={2} className="d-flex align-items-center justify-content-center">
                    <h6 className="mb-0 fw-bold text-center">{t('general.nbOfProducts')}</h6>
                </Col>
                <Col md={1} className="d-flex align-items-center justify-content-center">
                    <h6 className="mb-0 fw-bold text-center">{t('general.status')}</h6>
                </Col>
                <Col md={2} className="d-flex align-items-center justify-content-center">
                    <h6 className="mb-0 fw-bold text-center">{t('general.payUntil')}</h6>
                </Col>
                <Col md={1} className="d-flex align-items-center justify-content-center">
                    <h6 className="mb-0 fw-bold text-center">{t('general.actions')}</h6>
                </Col>

            </Row>
            <Row>
                {!!invoices?.length ? invoices?.map((invoice) => {
                    const statusColor = getStatusColor(invoice?.status!)?.light;
                    const canEdit = invoice.status === 'issued';
                    return (
                        <React.Fragment key={invoice.id}>
                            <Col md={1}
                                 className={`mt-2 d-flex align-items-center border-left-radius justify-content-center ${statusColor} cursor-pointer  `}>
                                <p className="mb-0 text-break text-center" style={{fontSize: 10}}>
                                    {invoice.id}
                                </p>
                            </Col>
                            <Col md={1}
                                 className={`mt-2 d-flex align-items-center justify-content-center ${statusColor} cursor-pointer  `}>
                                <p className="mb-0">
                                    {invoice?.nbOfInvoice}
                                </p>
                            </Col>
                            <Col md={4}
                                 className={`mt-2 d-flex align-items-center justify-content-center ${statusColor} cursor-pointer `}>
                                <p className="mb-0">
                                    {invoice?.invoiceTo?.name} - {invoice?.invoiceTo?.address} - {invoice?.invoiceTo?.email}
                                </p>
                            </Col>
                            <Col md={2}
                                 className={`mt-2 d-flex align-items-center justify-content-center ${statusColor} cursor-pointer `}>
                                <p className="mb-0">
                                    {invoice?.products?.length}
                                </p>
                            </Col>
                            <Col md={1}
                                 className={`mt-2 d-flex align-items-center justify-content-center ${statusColor} cursor-pointer `}>
                                <Badge bg={getStatusColor(invoice?.status!)?.badge}
                                       className="text-uppercase">{t(`general.${invoice?.status}`)}</Badge>
                            </Col>

                            <Col md={2}
                                 className={`mt-2 d-flex align-items-center justify-content-center ${statusColor} cursor-pointer `}>
                                {formatDate(invoice?.payUntil)}
                            </Col>

                            <Col md={1}
                                 className={`mt-2 d-flex align-items-center border-right-radius justify-content-center 
                                  ${statusColor}
                                  cursor-pointer `}>
                                {canEdit ? <>
                                    <Badge bg="warning"
                                           onClick={() => navigate(`/update-invoice/${invoice?.id}`)}
                                    >
                                        <Pencil className="text-white" size={15}/>
                                    </Badge>
                                    <Badge bg="danger" onClick={() => handleDeleteInvoice(String(invoice?.id))}
                                           className="ms-2">
                                        <Trash size={15}/>
                                    </Badge>
                                </> : t('general.invoiceNotEditable')}
                            </Col>
                        </React.Fragment>
                    )
                }) : <React.Fragment>
                    <Col xs={12} className="d-flex justify-content-center mt-4">
                        <h5>{t('general.listIsEmpty')}</h5>
                    </Col>

                </React.Fragment>}
            </Row>
        </div>
    );
};

export default TableList;
