import {Dispatch, UnknownAction} from '@reduxjs/toolkit';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, {useState} from 'react';
import {Button, Card, CardBody, Container, Form, Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {formatDate} from '../../functions/formatDate';
import {toastSlice} from '../../store/slices/toasts.slice';
import PreviewContract from './components/PreviewContract';

interface SenderInfo {
    fullName: string;
    name: string;
    address: string;
    mobile: string;
    street: string;
    fn: string;
    website: string;
    fr: string;
    email: string;
}

interface ReceiverInfo {
    companyInfo: string;
    description: string;
}

export interface IWordData {
    from: SenderInfo;
    to: ReceiverInfo;
}


const initialState = {
    from: {
        fullName: 'Pero Mitrovic',
        name: 'PERO ESTRICH e.U.',
        address: 'Dr.Karl-Liebleitner-Ring 26/1b',
        mobile: '0650/45-00-838',
        street: 'A-2100 KORNEUBURG',
        fn: 'FN 622240i',
        website: 'https://www.pero-estrich.com/',
        fr: '33 Fr 297/24 t – 3',
        email: 'office@pero-estrich.com',
    },
    to: {
        companyInfo: '',
        description: ''
    }
}

const generateContract = (nbOfInvoice: string,
                          dispatch: Dispatch<UnknownAction>,
                          navigation: NavigateFunction) => {
    html2canvas(document.querySelector('#invoice-contract')!).then(async (canvas) => {
        const imgData = canvas.toDataURL('image/png', 1.0);
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'pt',
            format: [612, 792]
        });
        pdf.internal.scaleFactor = 1;
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(`${nbOfInvoice}.pdf`);
        navigation(-1);
        dispatch(toastSlice.actions.setToast({
            type: 'success',
            message: `Uspesno izgenerisana faktura br ${nbOfInvoice}`
        }))
    });
}


const WordFile = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [isModalOpened, setIsModalOpened] = useState(false)

    const [currentInformations, setCurrentInformations] = useState<IWordData>(initialState);
    const handleChange = (name: string, value: string) => {
        setCurrentInformations(prev => ({...prev, to: {...prev.to, [name]: value}}))
    }
    const handleCloseModal = () => {
        setIsModalOpened(false)
    }
    return (
        <>
            <Container className="my-5">
                <Button
                    onClick={() => navigate(-1)}
                    variant="outline-primary">
                    {t('general.back')}
                </Button>
                <Card className="mt-3">
                    <CardBody>
                        <div className="d-flex justify-content-end">
                            <div className="d-flex flex-column align-items-end">
                                <img src="/logo.png"
                                     className="w-25"
                                     alt="Company logo"/>
                                <div className="d-flex flex-column mt-3">
                                    <h4 className="text-end fw-bold">{currentInformations.from.name}</h4>
                                    <h6 className="text-end">{currentInformations.from.address}</h6>
                                    <h6 className="text-end">{currentInformations.from.street}</h6>
                                    <h6 className="text-end">
                                        {currentInformations.from.fn}
                                    </h6>
                                    <h6 className="text-end">
                                        {currentInformations.from.fr}
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="w-50">
                            <Form.Control type="text"
                                          as="textarea" rows={3}
                                          onChange={(event) => {
                                              handleChange('companyInfo', event.target.value)
                                          }}
                                          className="w-100 my-1"
                                          placeholder="Za koga?"/>
                        </div>
                        <h5 className="text-end mt-3">
                            Korneuburg, {formatDate(new Date().toISOString())}
                        </h5>
                        <Form.Control type="text"
                                      onChange={(event) => {
                                          handleChange('description', event.target.value)
                                      }}
                                      as="textarea" rows={3}
                                      className="w-100 my-1"
                                      placeholder="Text"/>

                        <hr/>
                        <p>Mit freundlichen Grüßen</p>

                        <p>
                            {currentInformations?.from?.fullName}
                        </p>

                        <p> A-2100 Korneuburg,</p>
                        <p>{currentInformations?.from?.address}</p>
                        <p>
                            Mobil: {currentInformations?.from?.mobile}
                        </p>
                        <p>
                            Website: {currentInformations?.from?.website}
                        </p>
                        <p>
                            E-mail: {currentInformations?.from?.email}
                        </p>
                        <Button variant="info" className="text-white w-100"
                                onClick={() =>
                                    setIsModalOpened(true)
                                    //
                                }>
                            {t('general.generate')}
                        </Button>
                    </CardBody>
                </Card>
            </Container>
            <Modal show={isModalOpened} onHide={handleCloseModal} centered size="lg">
                <PreviewContract currentInformations={currentInformations}
                                 handleGenerate={() => generateContract(`Ugovor za - ${currentInformations.to.companyInfo}`, dispatch, navigate)}/>
            </Modal>
        </>
    );
};

export default WordFile;
