import {createSlice} from '@reduxjs/toolkit';

const initialVersionSlice: IToast[] = []

export interface IToast {
    type: 'success' | 'danger';
    message: string;
    timestamp: number
}

export const toastSlice = createSlice({
    name: 'toastSlice',
    initialState: initialVersionSlice,
    reducers: {
        setToast: (state, action) => {
            state = state.concat({
                ...action.payload,
                message: action.payload.message,
                timestamp: Date.now(),
            });
            return state;
        },
        resetToasts: (state) => {
            state = [];
            return state;
        },
        removeExpiredToasts: (state) => {
            const currentTime = Date.now();
            return state.filter((toast) => currentTime - toast.timestamp <= 3000);
        },
        removeToastById: (state, action) => {
            return state.filter((toast) => toast.timestamp !== action.payload.value);
        },
    },
});
