import React from 'react';
import {Form} from 'react-bootstrap';
import {FieldErrors, UseFormRegister} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {IInvoiceData} from '../app/form/InvoiceForm';


interface IProps {
    register: UseFormRegister<IInvoiceData>;
    errors: FieldErrors<IInvoiceData>
}

const BillTo: React.FC<IProps> = ({register, errors}) => {

    const {t} = useTranslation()
    return (
        <div>
            <span className="fw-bold">
                {t('general.billTo')}
            </span>
            <div>
                <Form.Control type="text" {...register('invoiceTo.name')} className="w-100 my-1"
                              isInvalid={!!errors?.invoiceTo?.name}
                              placeholder={t('general.invoiceByWho')}/>
                {!!errors?.invoiceTo?.name && <div className="invalid-feedback">
                    {errors?.invoiceTo?.name?.message}
                </div>}
                <Form.Control type="email" className="w-100 my-2"{...register('invoiceTo.email')}
                              isInvalid={!!errors?.invoiceTo?.email}
                              placeholder={t('general.emailAddress')}/>
                {!!errors?.invoiceTo?.email && <div className="invalid-feedback">
                    {errors?.invoiceTo?.email?.message}
                </div>}
                <Form.Control as="textarea" type="text" className="w-100 my-2" {...register('invoiceTo.address')}
                              isInvalid={!!errors?.invoiceTo?.address}
                              placeholder={t('general.billingAddress')}/>
                {!!errors?.invoiceTo?.address && <div className="invalid-feedback">
                    {errors?.invoiceTo?.address?.message}
                </div>}
                <Form.Control type="text" className="w-100 my-2" {...register('uid')}
                              isInvalid={!!errors?.uid}
                              placeholder={t('general.uid')}/>
                {!!errors?.uid && <div className="invalid-feedback">
                    {errors?.uid?.message}
                </div>}
                <Form.Control type="text" as="textarea" className="w-100 my-2" {...register('projectPlace')}
                              isInvalid={!!errors?.projectPlace}
                              placeholder={t('general.projectPlace')}/>
                {!!errors?.projectPlace && <div className="invalid-feedback">
                    {errors?.projectPlace?.message}
                </div>}
            </div>
        </div>
    );
};

export default BillTo;
