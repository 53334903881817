import {yupResolver} from '@hookform/resolvers/yup';
import {signInWithEmailAndPassword} from 'firebase/auth';
import React from 'react';
import {Button, Card, Col, Container, Form, Row} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {auth} from '../../firebase';
import authSlice from '../../store/slices/auth.slice';
import {toastSlice} from '../../store/slices/toasts.slice';

interface ILoginData {
    username: string;
    password: string;
}

const schema = yup.object({
    username: yup.string().email().required().required(),
    password: yup.string().required()
});
const LoginPage = () => {
    const navigation = useNavigate();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<ILoginData>({
        resolver: yupResolver(schema),
    });
    const onFormSubmit = async (formData: ILoginData) => {
        try {
            await signInWithEmailAndPassword(auth, formData?.username, formData?.password).then(({user}) => {
                navigation('/invoice-list')
                dispatch(authSlice.actions.setLoginState(user))
            });
            dispatch(toastSlice.actions.setToast({
                type: 'success',
                message: t('general.successfullyLoggedIn')
            }))
            // await auth.signInWithEmailAndPassword(auth, formData?.username, formData?.password);
        } catch (err) {
            dispatch(toastSlice.actions.setToast({
                type: 'danger',
                // @ts-ignore
                message: (err?.message)
            }))
            console.error(err);
        }
    }

    return (
        <div className="d-flex align-items-center justify-content-center App">
            <Form className="is-alter d-none d-md-block" onSubmit={handleSubmit(onFormSubmit)}>

                <Row className="justify-content-center">
                    <Col md={6}>
                        <Container>
                            <Card>
                                <Card.Body>
                                    <Row className="gy-2">
                                        <Col md={12} className="d-flex justify-content-center">
                                            <img src="/logo.png" alt="Logo of company" className="w-50"/>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Control type="text"
                                                          {...register('username')}
                                                          isInvalid={!!errors?.username}
                                                          className="w-100 my-1"
                                                          placeholder={t('general.username')}/>
                                            {errors?.username && <div className="invalid-feedback">
                                                {errors?.username?.message}
                                            </div>}
                                        </Col>
                                        <Col md={12}>
                                            <Form.Control type="password"
                                                          {...register('password')}
                                                          className="w-100 my-1 "
                                                          isInvalid={!!errors?.password}
                                                          placeholder={t('general.password')}/>
                                            {errors?.password && <div className="invalid-feedback">
                                                {errors?.password?.message}
                                            </div>}
                                        </Col>
                                        <Col md={12}>
                                            <Button variant="primary" type="submit" className="w-100">
                                                {t('general.login')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Container>
                    </Col>
                </Row>
            </Form>
            <h1 className="d-md-none">Nije podržano za telefon :)</h1>
        </div>
    );
};

export default LoginPage;
