import {Dispatch, UnknownAction} from '@reduxjs/toolkit';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'
import React from 'react';
import {Button, Col, Row, Table} from 'react-bootstrap';
import {EnvelopePaper} from 'react-bootstrap-icons';
import {UseFormGetValues, UseFormWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {NavigateFunction, useNavigate, useParams} from 'react-router-dom';
import {IInvoiceData} from '../app/form/InvoiceForm';
import {db} from '../firebase';
import {formatDate} from '../functions/formatDate';
import {calculateDiscount, calculateTax, formatPrice} from '../functions/formatPrice';
import {toastSlice} from '../store/slices/toasts.slice';
import {collection, addDoc, updateDoc, doc} from 'firebase/firestore';

interface IProps {
    handleClose: () => void;
    getValues: UseFormGetValues<IInvoiceData>;
    watch: UseFormWatch<IInvoiceData>
}

const generateInvoice = (nbOfInvoice: string,
                         handleClose: () => void,
                         dispatch: Dispatch<UnknownAction>,
                         getValues: UseFormGetValues<IInvoiceData>,
                         navigation: NavigateFunction,
                         invoiceId?: string
) => {
    html2canvas(document.querySelector('#invoiceCapture')!).then(async (canvas) => {
        const imgData = canvas.toDataURL('image/png', 1.0);
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'pt',
            format: [612, 792]
        });
        pdf.internal.scaleFactor = 1;
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        const invoiceDivHeight = (document.querySelector('#invoiceCapture')! as HTMLDivElement)?.getBoundingClientRect().height;
        const pageHeight = pdf.internal.pageSize.height;
        const correctHeight = invoiceDivHeight > 1000 ? pageHeight : pdfHeight
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, correctHeight);
        pdf.save(`${nbOfInvoice}.pdf`);
        handleClose();
        if (!getValues('status')) {
            const docRef = await addDoc(collection(db, 'invoices'), {
                invoices: {
                    ...getValues(),
                    status: 'issued'
                }
            });
            if (docRef) {
                navigation(-1);
                dispatch(toastSlice.actions.setToast({
                    type: 'success',
                    message: `Uspesno izgenerisana faktura br ${nbOfInvoice}`
                }))
            }
        } else { // update
            if (!invoiceId) {
                console.error(`Missing Invoice ID! Provided: ${invoiceId}`)
                return;
            }
            const docRef = doc(db, 'invoices', invoiceId); // Replace 'YOUR_DOCUMENT_ID' with the ID of the document you want to update
            await updateDoc(docRef, {
                invoices: {
                    ...getValues(),
                }
            }).then(response => {
                console.log(response)
                navigation(-1);
                dispatch(toastSlice.actions.setToast({
                    type: 'success',
                    message: `Uspesno ažurirana faktura br ${nbOfInvoice}`
                }))
            });
        }
    });
}

const ModalBody: React.FC<IProps> = ({watch, handleClose, getValues}) => {
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const {id} = useParams();
    const {t} = useTranslation();
    const totalPrice = Number(watch('products')?.reduce((curr, acc) => (acc?.price * Number(acc?.quantity)) + curr, 0)) || 0;
    const grandTotal = (totalPrice + calculateTax(totalPrice, +watch('tax'))) - calculateDiscount(totalPrice, +watch('discount'))
    return (
        <>
            <div id="invoiceCapture">
                <div className="d-flex flex-row justify-content-between align-items-center bg-light w-100 p-4">

                    <div>
                        <img src="/logo.png" alt="Company logo" className="w-40"/>
                        {/*<h6 className="fw-bold mt-1 mb-2">{t('general.totalAmount')}:</h6>*/}
                        {/*<h5 className="fw-bold text-secondary"> {formatPrice(grandTotal)}</h5>*/}
                    </div>
                    <div className="w-75 text-end">
                        <div className="fw-bold ">PERO ESTRICH e.U</div>
                        <div>Pero Mitrović</div>
                        <div>Dr.Karl-Liebleitner-Ring 26/1b</div>
                        <div>A-2100 Korneuburg</div>
                        <div>Österreich</div>
                    </div>
                </div>
                <div className="p-4">
                    <Row className="mb-4 align-items-start">
                        <Col md={12}>
                            <div className="fw-bold text-uppercase">{t('general.billTo')}</div>
                            <div className="fw-bold">{watch('invoiceTo.name')}</div>
                            <div>{watch('invoiceTo.email')}</div>

                            <div>
                                {watch('invoiceTo.address')?.split('\n').map(word => <p
                                    className="mb-0">{word}</p>)}
                            </div>
                            <div className="d-flex align-items-center ">
                                <div className="fw-bold text-uppercase">{t('general.uid')}:{' '}</div>
                                <div> {watch('uid')}</div>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="d-flex align-items-center justify-content-end">
                                <div className="fw-bold text-uppercase">{t('general.dateOfIssue')}:</div>
                                <div> {formatDate(watch('payUntil'))}</div>
                            </div>
                        </Col>
                        <Col md={12} className="my-3">
                            <div className="d-flex align-items-center ">
                                <div className="fw-bold text-uppercase">{t('general.nbOfInvoice')}:</div>
                                <div> {watch('nbOfInvoice')}</div>
                            </div>
                            <div className="d-flex align-items-center my-1">
                                <div className="fw-bold text-uppercase">{t('general.dateOfWork')}:</div>
                                <div> {watch('dateOfWork')}</div>
                            </div>
                            <div className="d-flex align-items-center ">
                                <div className="fw-bold text-uppercase">{t('general.projectPlace')}:</div>
                                <div> {watch('projectPlace')}</div>
                            </div>
                        </Col>

                    </Row>
                    <Table className="mb-0">
                        <thead>
                        <tr>
                            <th>{t('general.nameOfService')}</th>
                            <th>{t('general.quantity')}</th>
                            <th className="text-end" style={{width: '130px'}}>{t('general.unitOfMeasure')}</th>
                            <th className="text-end">{t('general.price')}</th>
                            <th className="text-end">{t('general.total')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {watch('products')?.map((item) => {
                            return (
                                <tr id={`${item?.createdAt}`} key={item?.createdAt}>
                                    <td>
                                        {item.name}
                                    </td>
                                    <td style={{width: '70px'}}>
                                        {item.quantity}
                                    </td>
                                    <td className="text-end"
                                        style={{width: '100px'}}>
                                        {item?.unit}
                                    </td>
                                    <td className="text-end"
                                        style={{width: '100px'}}> {formatPrice(item?.price)}</td>
                                    <td className="text-end"
                                        style={{width: '100px'}}> {formatPrice(item?.price * Number(item?.quantity))}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </Table>
                    <Table className="mb-5">
                        <tbody>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr className="text-end">
                            <td>
                                {Number(watch('tax')) === 0 && <div>
                        <span className="w-80 d-block">
                            {watch('textRabat')?.split('\n').map(word => <p className="mb-0">{word}</p>)}
                        </span>
                                </div>}
                            </td>
                            <td className="fw-bold" style={{width: '100px'}}>{t('general.subtotal')}</td>
                            <td className="text-end"
                                style={{width: '100px'}}>
                                {formatPrice(totalPrice)}
                            </td>
                        </tr>
                        {watch('tax') !== 0 &&
                            <tr className="text-end">
                                <td></td>
                                <td className="fw-bold text-uppercase" style={{width: '100px'}}>{t('general.tax')}</td>
                                <td className="text-end"
                                    style={{width: '100px'}}>
                                    ({watch('tax')}%) {formatPrice(calculateTax(totalPrice, +watch('tax')))}
                                </td>
                            </tr>
                        }
                        {watch('discount') !== 0 &&
                            <tr className="text-end">
                                <td></td>
                                <td className="fw-bold text-uppercase" style={{width: '100px'}}>{t('discount')}</td>
                                <td className="text-end"
                                    style={{width: '100px'}}>
                                    ({watch('discount')}%) {formatPrice(calculateDiscount(totalPrice, +watch('discount')))}
                                </td>
                            </tr>
                        }
                        <tr className="text-end">
                            <td>
                            </td>
                            <td className="fw-bold text-uppercase"
                                style={{width: '100px'}}>{t('general.total')}</td>
                            <td className="text-end"
                                style={{width: '100px'}}>
                                {formatPrice(grandTotal)}
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                    {!!watch('comment') && <div className="border-top mt-5 mb-3 pt-3">
                        {watch('comment')}
                    </div>}

                    <div className="d-flex justify-content-between border-top pt-3">
                        <div className="d-flex flex-column">
                            <span className="fs-11px text-muted fw-medium">
                                Inhaber: Pero Mitrovic
                            </span>
                            <span className="fs-11px text-muted fw-medium">
                                Tel.Nr: 0681/20-400-568

                            </span>
                            <span className="fs-11px text-muted fw-medium">
                                Büro: 0650/45-00-838
                            </span>
                            <span className="fs-11px text-muted fw-medium">
                                E-Mail: office@pero-estrich.com

                            </span>
                            <span className="fs-11px text-muted fw-medium">
                                Website: www.pero-estrich.com
                            </span>
                        </div>
                        <div className="d-flex flex-column">
                            <span className="fs-11px text-muted fw-medium">
                                BAWAG Bank
                           </span>
                            <span className="fs-11px text-muted fw-medium">
                                IBAN: AT97 1400 0009 1020 3932
                            </span>
                            <span className="fs-11px text-muted fw-medium">
                                BIC: BAWAATWW
                           </span>
                        </div>
                        <div className="d-flex flex-column">
                              <span className="fs-11px text-muted fw-medium">
                                Handelsgericht: Korneuburg
                            </span>
                            <span className="fs-11px text-muted fw-medium">
                                FN 622240 i
                            </span>
                            <span className="fs-11px text-muted fw-medium">
                                UID Nr: ATU80527878
                            </span>
                            <span className="fs-11px text-muted fw-medium">
                                Dienstgebernummer: 202369385
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pb-4 px-4">
                <Row>
                    <Col md={6}>
                        <Button variant="outline-primary" className="d-block w-100" onClick={handleClose}>
                            {t('general.close')}
                        </Button>
                    </Col>
                    <Col md={6}>
                        <Button variant="primary" className="d-block w-100 mt-3 mt-md-0"
                                onClick={() => generateInvoice(watch('nbOfInvoice'), handleClose, dispatch, getValues, navigation, id)}>
                            <EnvelopePaper style={{width: '16px', height: '16px', marginTop: '-3px'}}
                                           className="me-2"/>
                            {t('general.generateInvoice')}
                        </Button>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default ModalBody;
