import {createSlice} from '@reduxjs/toolkit';

interface IFirebaseEmailLoginResponse {
    accessToken: string;
    auth: {
        displayName: string | null;
        email: string;
        emailVerified: boolean;
        isAnonymous: boolean;
        metadata: {
            createdAt: string; // Date string
            lastLoginAt: string; // Date string
            lastSignInTime: string; // Date string
            creationTime: string; // Date string
        };
        phoneNumber: string | null;
        photoURL: string | null;
        providerId: string;
        reloadUserInfo: {
            localId: string;
            email: string;
            passwordHash: string;
            emailVerified: boolean;
            passwordUpdatedAt: string; // Date string
            creationTime: string; // Date string
        };
        stsTokenManager: {
            refreshToken: string;
            accessToken: string;
            expirationTime: number; // Unix timestamp in milliseconds
        };
        tenantId: string | null;
        uid?: string;
        // You can add more properties here if needed
    };
}

interface AuthState {
    isAppReady: boolean;
    isAuthenticated: boolean;
    user: IFirebaseEmailLoginResponse | null;
}

const initialState: AuthState = {
    isAppReady: false,
    isAuthenticated: false,
    user: null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoginState: (state, action) => {
            state.isAuthenticated = true;
            state.user = action.payload
            return state;
        },
        setIsAppReady: (state, action) => {
            state.isAppReady = action.payload;
            return state;
        },
        setLogoutState: (state) => {
            state = initialState;
            return state;
        },
    },
});

export default authSlice;
