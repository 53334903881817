export const getStatusColor = (status: string) => {
    const statusColors = {
        paid: {
            badge: 'success',
            primary: 'bg-success',
            light: 'bg-success-subtle'
        },
        issued: {
            badge: 'info',
            primary: 'bg-info',
            light: 'bg-info-subtle'
        },
        declined: {
            badge: 'danger',
            primary: 'bg-danger',
            light: 'bg-danger-subtle'
        }
    }
    return statusColors?.[status as keyof typeof statusColors] || ''
}