import moment from 'moment';
import React, {useEffect} from 'react';
import {Toast} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {toastSlice} from '../store/slices/toasts.slice';
import {RootState} from '../store/store';

const Toaster = () => {
    const dispatch = useDispatch();
    const {toasts} = useSelector((state: RootState) => state)
    const handleCloseToast = () => {
        dispatch(toastSlice.actions.removeExpiredToasts());
    };


    useEffect(() => {
        if (!!toasts?.length) {
            setTimeout(() => {
                handleCloseToast();
            }, 3000);
        }
        return () => {
            if (!!toasts?.length) {
                setTimeout(() => {
                    handleCloseToast();
                }, 3000);
            }
        };
// eslint-disable-next-line
    }, [JSON.stringify(toasts)]);

    return (
        <div className="toast-container">
            {toasts?.map(toast => {
                const title = toast.type === 'success' ? 'Success' : 'Error'
                return (
                    <Toast
                        key={toast?.timestamp}
                        className="d-inline-block m-1"
                        bg={toast?.type}
                    >
                        <Toast.Header>
                            <img
                                src="holder.js/20x20?text=%20"
                                className="rounded me-2"
                                alt=""
                            />
                            <strong className="me-auto">{title}</strong>
                            <small>{moment(toast.timestamp).fromNow()}</small>
                        </Toast.Header>
                        <Toast.Body className={'text-white'}>
                            {toast?.message}
                        </Toast.Body>
                    </Toast>
                )
            })}

        </div>
    );
};

export default Toaster;
