import React from 'react';
import {Form} from 'react-bootstrap';
import {FieldErrors, UseFormRegister} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {IInvoiceData} from '../app/form/InvoiceForm';

interface IProps {
    register: UseFormRegister<IInvoiceData>;
    errors: FieldErrors<IInvoiceData>
}

const BillFrom: React.FC<IProps> = ({register, errors}) => {
    const {t} = useTranslation();
    return (
        <div>
              <span className="fw-bold">
                {t('general.billBy')}
            </span>
            <div>
                <Form.Control type="text"
                              isInvalid={!!errors?.invoiceFrom?.name}
                              {...register('invoiceFrom.name')}
                              value="Pero Estrich e.U"
                              className="w-100 my-1" placeholder={t('general.nameOfCompany')}/>

                <Form.Control type="email" className="w-100 my-2"
                              isInvalid={!!errors?.invoiceFrom?.email}
                              {...register('invoiceFrom.email')}
                              value="office@pero-estrich.com"
                              placeholder={t('general.emailAddress')}/>
                <Form.Control type="text"
                              isInvalid={!!errors?.invoiceFrom?.address}
                              {...register('invoiceFrom.address')}
                              value="Dr.Karl-Liebleitner-Ring 26/1b, A-2100 Korneuburg"
                              className="w-100 my-2" placeholder={t('general.billingAddress')}/>
                {!!errors?.invoiceFrom && <div className="invalid-feedback">
                    {errors?.invoiceFrom?.message}
                </div>}
            </div>
        </div>
    );
};

export default BillFrom;
