export const formatPrice = (price: number, minimumFractionDigits = 2) => {
    return Intl.NumberFormat('en-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: minimumFractionDigits,
    }).format(price)
};


export function calculateTax(totalPrice: number, taxPercentage: number): number {
    const taxAmount = totalPrice * (taxPercentage / 100)
    return Number(taxAmount);
}

export function calculateDiscount(totalPrice: number, discountPercentage: number): number {
    const discountAmount = Number(totalPrice) * Number(discountPercentage / 100);
    return Number(totalPrice - Number(totalPrice - discountAmount));
}