import React from 'react';
import {Button} from 'react-bootstrap';
import {IWordData} from '../index';

interface IProps {
    currentInformations: IWordData;
    handleGenerate: () => void;
}

const PreviewContract = ({currentInformations, handleGenerate}: IProps) => {
    return (
        <div>
            <div className="container p-5" id="invoice-contract">
                <div className="d-flex justify-content-end">
                    <img src="/logo.png"
                         className="w-25"
                         alt="Company logo"/>
                </div>
                <div className="d-flex flex-column mt-3">
                    <h4 className="text-end fw-bold">{currentInformations.from.name}</h4>
                    <h6 className="text-end">{currentInformations.from.address}</h6>
                    <h6 className="text-end">{currentInformations.from.street}</h6>
                    <h6 className="text-end">
                        {currentInformations.from.fn}
                    </h6>
                    <h6 className="text-end">
                        {currentInformations.from.fr}
                    </h6>
                </div>
                <div className="d-flex mt-3">
                    <div className=" fw-bold"
                    >
                        {currentInformations.to.companyInfo?.split('\n').map(word => <p className="mb-0">{word}</p>)}
                    </div>
                </div>
                <div className="my-5 py-5">
                    <p>{currentInformations.to.description?.split('\n').map(word => <p
                        className="mb-0">{word}</p>)}</p>
                </div>
                <div className="mt-5 pt-5">
                    <p className="mb-0">Mit freundlichen Grüßen</p>

                    <p className="mb-0">
                        {currentInformations?.from?.fullName}
                    </p>

                    <p className="mb-0"> A-2100 Korneuburg,</p>
                    <p className="mb-0">{currentInformations?.from?.address}</p>
                    <p className="mb-0">
                        Mobil: {currentInformations?.from?.mobile}
                    </p>
                    <p className="mb-0">
                        Website: {currentInformations?.from?.website}
                    </p>
                    <p className="mb-0">
                        E-mail: {currentInformations?.from?.email}
                    </p>
                </div>
            </div>
            <div className="m-3">
                <Button variant="info" className="text-white w-100 " onClick={handleGenerate}>
                    Generate
                </Button>
            </div>
        </div>
    );
};

export default PreviewContract;
